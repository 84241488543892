import { useAnalytics } from "@/hooks/useAnalytics";
import { useSession } from "@/hooks/useSession";
import { capitalizeFirstLetter } from "@/lib/utils";
import styles from "@/styles/authorize.module.scss";
import {
  Alert,
  Button,
  Flex,
  Heading,
  Text,
  TextField,
} from "@talent-garden/react-components";
import { resendSignUpCode } from "aws-amplify/auth";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import { Spinner } from "../..";

const ConfirmSignUp: FC<{
  username: string;
  password: string;
  returnUrl?: string;
}> = ({ username, password, returnUrl }) => {
  const { t } = useTranslation("sign-in");
  const { handleSignUpConfirmation } = useSession();

  const [confirmationCode, setConfirmationCode] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);

  const { captureInteraction } = useAnalytics();

  const fakeEmail = useMemo(() => {
    if (!username) {
      return null;
    }
    const emailSplitted = username.split("@");
    return `${emailSplitted?.[0]?.[0]}****@${emailSplitted[1][0]}****`;
  }, [username]);

  const onSuccessAlertOpen = useCallback(
    (open: boolean) => setSuccessAlertOpen(open),
    [],
  );

  return (
    <>
      <Flex rowGap={8} columnGap={8} horizontalAlign="start">
        <Heading variant="m" weight="bold" style={{ paddingTop: "24px" }}>
          {t("confirm-account-title")}
        </Heading>
        <Text
          variant="m"
          style={{ marginBottom: "16px" }}
          className={styles.description}
        >
          {t("confirm-sign-up-description", { fakeEmail })}
        </Text>
        <TextField
          dimension="medium"
          label={t("confirm-sign-up-verification-code-label") as string}
          placeholder="000000"
          pattern="[0-9]*"
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.persist();
            setConfirmationCode(e.target.value);
          }}
        />

        <Button
          capitalize={false}
          dimension="regular"
          kind="primary"
          onClick={async () => {
            setSubmitting(true);
            try {
              setError("");
              const res = await handleSignUpConfirmation({
                username,
                confirmationCode,
                password,
                returnUrl,
              });
              if (res?.error) {
                setError(res?.error);
              } else {
                captureInteraction({
                  event_category: "onboarding",
                  event_label: "confirm",
                  section: "registration",
                });
              }
            } catch (err: any) {
              console.log("Error: ", err);
              setError(err?.message as string);
            } finally {
              setSubmitting(false);
            }
          }}
          fullWidth
        >
          {!submitting ? t("confirm-sign-up-cta") : <Spinner />}
        </Button>
        <Text
          variant="s"
          className={styles.error}
          weight="semibold"
          textAlign="center"
        >
          {capitalizeFirstLetter(error)}
        </Text>
        <Flex direction="row" rowGap={4} columnGap={4} verticalAlign="center">
          <Text variant="m">{t("confirm-sign-up-resend-code")}</Text>
          <Button
            capitalize={false}
            dimension="regular"
            kind="link-neutral"
            onClick={async () => {
              if (error) {
                setError("");
              }
              try {
                await resendSignUpCode({ username });
                setSuccessAlertOpen(true);
              } catch (err: any) {
                setError(err?.message as string);
              }
            }}
          >
            {t("confirm-sign-up-resend-code-cta")}
          </Button>
        </Flex>
      </Flex>
      <Alert
        title={t("verification-code-alert-title")}
        description={t("verification-code-alert-subtitle")}
        isOpen={successAlertOpen}
        handleOpenChange={onSuccessAlertOpen}
        icon="check_circle"
        withDismiss
        type="success"
        duration={4000}
        iconVariant="outlined"
      />
    </>
  );
};

export default ConfirmSignUp;
