import { useSession } from "@/hooks/useSession";
import { capitalizeFirstLetter } from "@/lib/utils";
import styles from "@/styles/authorize.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Flex,
  Heading,
  PasswordField,
  Text,
  TextField,
} from "@talent-garden/react-components";
import { signInWithRedirect } from "aws-amplify/auth";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import { useEffect, useState } from "react";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useAnalytics } from "@/hooks/useAnalytics";
import { Image, Spinner } from "../..";
import { ConfirmSignUp } from "../confirmSignUp";

const schema = yup.object().shape({
  username: yup
    .string()
    .email("valid-email")
    .required("missing-email")
    .typeError("invalid-email"),
  password: yup.string().required("password-required"),
});
export type SignInSchema = yup.InferType<typeof schema>;

const SignIn: FC<{
  goToSignUp: () => void;
  goToForgotPassword: () => void;
  returnUrl?: string;
}> = ({ goToSignUp, goToForgotPassword, returnUrl }) => {
  const { t } = useTranslation("sign-in");
  const { handleSignIn } = useSession();
  const { captureInteraction } = useAnalytics();

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [nextStep, setNextStep] = useState<{ username: string } | null>(null);

  const formMethods = useForm<SignInSchema>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = formMethods;

  const onSubmit: SubmitHandler<SignInSchema> = async (data: any) => {
    setSubmitting(true);
    try {
      setError("");
      const res = await handleSignIn({
        username: data.username?.toLowerCase(),
        password: data?.password,
        returnUrl,
      });

      if (res?.error) {
        setError(res?.error);
      }

      if (res?.nextStep) {
        setNextStep(res?.nextStep as any);
      } else {
        captureInteraction({
          event_category: "signin",
          event_label: "email",
        });
      }
    } catch (err) {
      console.log("Error: ", err);
      setError(err as string);
    } finally {
      setSubmitting(false);
    }
  };

  const onError: SubmitErrorHandler<SignInSchema> = (err, e) => {
    console.error("Errors: ", err, e);
  };

  const [password] = watch(["password"]);

  // KEY DOWN ENTER TO SIGN IN
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      try {
        if (event.code === "Enter") {
          handleSubmit(onSubmit, onError)();
        }
      } catch (err) {
        console.log("Error:", err);
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  if (nextStep) {
    return (
      <ConfirmSignUp
        username={nextStep?.username?.toLowerCase()}
        password={password}
        returnUrl={returnUrl}
      />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Flex rowGap={8} columnGap={8} horizontalAlign="start">
        <Heading variant="m" weight="bold">
          {t("sign-in-title")}
        </Heading>
        <Flex direction="row" rowGap={4} columnGap={4} verticalAlign="center">
          <Text variant="m" className={styles.description}>
            {t("sign-in-dont-have-account")}
          </Text>
          <Button
            capitalize={false}
            dimension="regular"
            kind="link"
            onClick={goToSignUp}
            fontWeight="semibold"
          >
            {t("sign-in-dont-have-account-button")}
          </Button>
        </Flex>
        <TextField
          dimension="medium"
          label={t("sign-in-email-label") as string}
          placeholder={t("sign-in-email-placeholder") as string}
          errorMessage={
            errors && errors?.username
              ? (t(`inputs:${errors?.username.message}`) as string)
              : undefined
          }
          {...register("username")}
        />

        <PasswordField
          label={t("sign-in-password-label") as string}
          placeholder={t("sign-in-password-placeholder") as string}
          noPaddingBottom
          // errorMessage={error ? true : null}
          errorMessage={
            errors && errors?.password
              ? (t(`inputs:${errors?.password.message}`) as string)
              : undefined
          }
          {...register("password")}
        />
        <Button
          capitalize={false}
          dimension="regular"
          kind="link"
          onClick={goToForgotPassword}
          fontWeight="semibold"
        >
          {t("sign-in-forgot-password")}
        </Button>
        <Button
          capitalize={false}
          dimension="regular"
          kind="primary"
          onClick={() => handleSubmit(onSubmit, onError)()}
          fullWidth
          // disabled={!username || !password}
        >
          {!submitting ? t("sign-in-cta") : <Spinner />}
        </Button>
        <Text
          variant="s"
          className={styles.error}
          weight="semibold"
          textAlign="center"
        >
          {capitalizeFirstLetter(error)}
        </Text>
        <Text
          variant="m"
          style={{ width: "100%", marginBottom: "6px" }}
          textAlign="center"
          className={styles.description}
        >
          {t("sign-in-social-description")}
        </Text>
        <Button
          capitalize={false}
          dimension="regular"
          kind="secondary-neutral"
          onClick={async () => {
            await signInWithRedirect({
              provider: "Google",
              customState: returnUrl ? `returnUrl=${returnUrl}` : undefined,
            });
          }}
          fullWidth
        >
          <Flex direction="row" rowGap={12} columnGap={12}>
            <Image
              src="/images/google_logo.svg"
              width={24}
              height={24}
              alt="google logo"
            />
            <Text variant="m" weight="semibold" className={styles.description}>
              {t("sign-in-google")}
            </Text>
          </Flex>
        </Button>
      </Flex>
    </FormProvider>
  );
};

export default SignIn;
