import { capitalizeFirstLetter } from "@/lib/utils";
import mainStyles from "@/styles/authorize.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Flex,
  Heading,
  MaterialIcon,
  PasswordField,
  Text,
  TextField,
} from "@talent-garden/react-components";
import { confirmResetPassword } from "aws-amplify/auth";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import { useMemo, useState } from "react";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { Spinner } from "../..";

import { CheckPasswordRequirements } from "../checkPasswordRequirements";
import styles from "./resetPassword.module.scss";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("password-required")
    .min(8, "password-char-long")
    .matches(/[0-9]/, "password-must-contain-number")
    .matches(/[A-Z]/, "password-must-contain-uppercase")
    .matches(/[a-z]/, "password-must-contain-lowercase")
    .matches(/[$-/:-?{-~!"^_@#`[\]]/, "password-must-contain-special"),
  confirmPwd: yup
    .string()
    .required("password-required")
    .oneOf([yup.ref("password")], "password-not-match"),
  otpCode: yup.string().required("missing-code"),
});
export type ResetPasswordSchema = yup.InferType<typeof schema>;

const ResetPassword: FC<{ username: string; goToSignIn: () => void }> = ({
  username,
  goToSignIn,
}) => {
  const { t } = useTranslation("sign-in");

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const fakeEmail = useMemo(() => {
    if (!username) {
      return null;
    }
    const emailSplitted = username.split("@");
    return `${emailSplitted?.[0]?.[0]}****@${emailSplitted[1][0]}****`;
  }, [username]);

  const formMethods = useForm<ResetPasswordSchema>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = formMethods;

  const onSubmit: SubmitHandler<ResetPasswordSchema> = async (data: any) => {
    try {
      setSubmitting(true);
      setError("");

      await confirmResetPassword({
        username,
        newPassword: data?.password,
        confirmationCode: data?.otpCode,
      });

      setPasswordChanged(true);
    } catch (err: any) {
      console.error(err);
      setError(err?.message as string);
    } finally {
      setSubmitting(false);
    }
  };

  const onError: SubmitErrorHandler<ResetPasswordSchema> = (err, e) => {
    console.error("Errors: ", err, e);
  };

  const [password] = watch(["password"]);

  if (passwordChanged) {
    return (
      <Flex rowGap={8} columnGap={8} horizontalAlign="start">
        <Flex
          className={styles.dot}
          verticalAlign="center"
          horizontalAlign="center"
        >
          <MaterialIcon name="check_circle" size={24} />
        </Flex>
        <Heading variant="m" weight="bold" style={{ paddingTop: "24px" }}>
          {t("password-changed-title")}
        </Heading>
        <Text variant="m" style={{ marginBottom: "16px" }}>
          {t("reset-password-description")}
        </Text>
        <Button
          capitalize={false}
          dimension="regular"
          kind="primary"
          onClick={goToSignIn}
          fullWidth
        >
          {t("reset-password-back-to-login")}
        </Button>
      </Flex>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Flex rowGap={8} columnGap={8} horizontalAlign="start">
        <Text
          variant="m"
          style={{ marginBottom: "16px" }}
          className={mainStyles.description}
        >
          {/* TODO translations */}
          We have sent a code by email to {fakeEmail}. Enter it below to reset
          your password.
        </Text>
        <TextField
          dimension="medium"
          label={t("confirm-sign-up-verification-code-label") as string}
          placeholder="000000"
          pattern="[0-9]*"
          type="text"
          {...register("otpCode")}
        />
        <PasswordField
          label="Password"
          placeholder="Use a strong password..."
          required
          // errorMessage={
          //   errors && errors?.password
          //     ? (t(`inputs:${errors?.password.message}`) as string)
          //     : undefined
          // }
          {...register("password")}
        />
        <PasswordField
          label="Confirm password"
          placeholder="Confirm your password..."
          required
          // className={accountStyles.input}
          data-margin-top="false"
          data-margin-bottom="true"
          errorMessage={
            errors && errors?.confirmPwd
              ? (t(`inputs:${errors?.confirmPwd.message}`) as string)
              : undefined
          }
          {...register("confirmPwd")}
        />
        <CheckPasswordRequirements password={password} />
        <Button
          capitalize={false}
          dimension="regular"
          kind="primary"
          onClick={() => handleSubmit(onSubmit, onError)()}
          fullWidth
          disabled={!!errors?.password}
        >
          {!submitting ? "Reset password" : <Spinner />}
        </Button>
        {error ? (
          <Text
            variant="s"
            className={mainStyles.error}
            weight="semibold"
            textAlign="center"
          >
            {capitalizeFirstLetter(error)}
          </Text>
        ) : null}
      </Flex>
    </FormProvider>
  );
};

export default ResetPassword;
