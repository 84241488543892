/* eslint-disable no-shadow */
import { Flex, MaterialIcon, Text } from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import { useMemo } from "react";
import styles from "./checkPasswordRequirements.module.scss";

const CheckPasswordRequirements: FC<{ password: string }> = ({ password }) => {
  const { t } = useTranslation("sign-in");

  const requirements: string[] = useMemo(() => {
    if (!password) {
      return [];
    }
    const requirements = [];

    if (password?.length >= 8) {
      requirements.push("length");
    }

    if (password?.match(/[A-Z]/) && password.match(/[a-z]/)) {
      requirements.push("upper-lower");
    }
    if (password?.match(/[$-/:-?{-~!"^_@#`[\]]/)) {
      requirements.push("special");
    }
    if (password?.match(/[0-9]/)) {
      requirements.push("number");
    }

    return requirements;
  }, [password]);

  return (
    <Flex
      horizontalAlign="start"
      rowGap={8}
      columnGap={8}
      className={styles.container}
    >
      <Text variant="s" textAlign="center" className={styles.description}>
        {t("password-requirements-strenght")}
      </Text>
      <Flex
        horizontalAlign="start"
        rowGap={4}
        columnGap={4}
        direction="row"
        verticalAlign="center"
        className={styles.requirement}
        data-success={requirements.includes("length")}
      >
        <MaterialIcon
          name={!requirements.includes("length") ? "close" : "check"}
          className={styles.icon}
        />
        <Text variant="s" textAlign="center" className={styles.text}>
          {t("password-requirements-8-chars")}
        </Text>
      </Flex>
      <Flex
        horizontalAlign="start"
        rowGap={4}
        columnGap={4}
        direction="row"
        verticalAlign="center"
        className={styles.requirement}
        data-success={requirements.includes("upper-lower")}
      >
        <MaterialIcon
          name={!requirements.includes("upper-lower") ? "close" : "check"}
          className={styles.icon}
        />
        <Text variant="s" textAlign="center" className={styles.text}>
          {t("password-requirements-upper-lower")}
        </Text>
      </Flex>
      <Flex
        horizontalAlign="start"
        rowGap={4}
        columnGap={4}
        direction="row"
        verticalAlign="center"
        className={styles.requirement}
        data-success={requirements.includes("special")}
      >
        <MaterialIcon
          name={!requirements.includes("special") ? "close" : "check"}
          className={styles.icon}
        />
        <Text variant="s" textAlign="center" className={styles.text}>
          {t("password-requirements-special-chars")}
        </Text>
      </Flex>
      <Flex
        horizontalAlign="start"
        rowGap={4}
        columnGap={4}
        direction="row"
        verticalAlign="center"
        className={styles.requirement}
        data-success={requirements.includes("number")}
      >
        <MaterialIcon
          name={!requirements.includes("number") ? "close" : "check"}
          className={styles.icon}
        />
        <Text variant="s" textAlign="center" className={styles.text}>
          {t("password-requirements-numbers")}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CheckPasswordRequirements;
