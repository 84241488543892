import { useAnalytics } from "@/hooks/useAnalytics";
import { useSession } from "@/hooks/useSession";
import { useTermsOfService } from "@/hooks/useTermsOfService";
import { capitalizeFirstLetter } from "@/lib/utils";
import styles from "@/styles/authorize.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Flex,
  Heading,
  PasswordField,
  Text,
  TextField,
} from "@talent-garden/react-components";
import { signInWithRedirect } from "aws-amplify/auth";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import type { SubmitErrorHandler, SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { Image, Spinner } from "../..";
import { CheckPasswordRequirements } from "../checkPasswordRequirements";
import { ConfirmSignUp } from "../confirmSignUp";

const schema = yup.object().shape({
  username: yup
    .string()
    .email("valid-email")
    .required("missing-email")
    .typeError("invalid-email"),
  password: yup
    .string()
    .required("password-required")
    .min(8, "password-char-long")
    .matches(/[0-9]/, "password-must-contain-number")
    .matches(/[A-Z]/, "password-must-contain-uppercase")
    .matches(/[a-z]/, "password-must-contain-lowercase")
    .matches(/[$-/:-?{-~!"^_@#`[\]]/, "password-must-contain-special"),
});
export type SignUpSchema = yup.InferType<typeof schema>;

const SignUp: FC<{ goToSignIn: () => void; returnUrl?: string }> = ({
  goToSignIn,
  returnUrl,
}) => {
  const { t } = useTranslation(["common", "sign-in"]);
  const { handleSignUp } = useSession();
  const { captureInteraction } = useAnalytics();
  const { terms } = useTermsOfService();

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [nextStep, setNextStep] = useState<{ username: string } | null>(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isMarketingUpdatesChecked, setIsMarketingUpdatesChecked] =
    useState(false);

  const formMethods = useForm<SignUpSchema>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = formMethods;

  const saveAgreements = useCallback(() => {
    if (!captureInteraction) {
      return;
    }
    if (isMarketingUpdatesChecked) {
      captureInteraction({
        event_category: "product_updates",
        event_label: "accept",
        section: "registration",
      });

      captureInteraction({
        event_category: "commercial_communications",
        event_label: "accept",
        section: "registration",
      });

      captureInteraction({
        event_category: "promotional_communications",
        event_label: "accept",
        section: "registration",
      });
    }

    if (terms) {
      captureInteraction({
        event_category: "tos",
        event_label: "accept",
        resource: terms?.version,
        section: "registration",
      });
    }
  }, [captureInteraction, terms, isMarketingUpdatesChecked]);

  const onSubmit: SubmitHandler<SignUpSchema> = async (data: any) => {
    try {
      setSubmitting(true);
      setError("");
      const res = await handleSignUp({
        username: data.username?.toLowerCase(),
        password: data.password,
      });

      if (res?.error) {
        setError(res?.error);
      } else {
        captureInteraction({
          event_category: "signup",
          event_label: "email",
          section: "registration",
        });
        saveAgreements();
      }

      if (res?.nextStep) {
        // DO SOMETHING
        setNextStep(res?.nextStep as any);
      }
    } catch (err) {
      console.error(err);
      setError(err as string);
    } finally {
      setSubmitting(false);
    }
  };

  const onError: SubmitErrorHandler<SignUpSchema> = (err, e) => {
    console.error("Errors: ", err, e);
  };

  // KEY DOWN ENTER TO SIGN IN
  useEffect(() => {
    if (!termsAccepted) {
      return;
    }
    const onKeyDown = (event: KeyboardEvent) => {
      try {
        if (event.code === "Enter") {
          handleSubmit(onSubmit, onError)();
        }
      } catch (err) {
        console.log("Error:", err);
      }
    };

    window.addEventListener("keydown", onKeyDown);

    /*  eslint-disable-next-line consistent-return */
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [termsAccepted]);

  const [username, password] = watch(["username", "password"]);

  if (nextStep) {
    return (
      <ConfirmSignUp
        username={nextStep?.username?.toLowerCase()}
        password={password}
        returnUrl={returnUrl}
      />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Flex rowGap={8} columnGap={8} horizontalAlign="start">
        <Heading variant="m" weight="bold">
          {t("sign-in:sign-up-title")}
        </Heading>
        <Flex direction="row" verticalAlign="center">
          <Text variant="m" className={styles.description}>
            {t("sign-in:sign-up-already-account")}
          </Text>
          <Button
            capitalize={false}
            dimension="regular"
            kind="link"
            onClick={goToSignIn}
            fontWeight="semibold"
          >
            {t("sign-in:sign-up-login-now")}
          </Button>
        </Flex>
        <TextField
          dimension="medium"
          label={t("sign-in:sign-up-email-label") as string}
          placeholder={t("sign-in:sign-up-email-placeholder") as string}
          errorMessage={
            submitted && errors && errors?.username
              ? (t(`inputs:${errors?.username.message}`) as string)
              : undefined
          }
          {...register("username")}
        />

        <PasswordField
          label={t("sign-in:sign-up-password-label") as string}
          placeholder={t("sign-in:sign-up-password-label") as string}
          required
          {...register("password")}
          noPaddingBottom
        />
        <CheckPasswordRequirements password={password} />

        {/* TOS */}
        <Flex rowGap={12} className={styles.tosWrapper}>
          <Checkbox
            kind="checked"
            label={
              <Trans
                i18nKey="sign-in:terms-of-service-checkbox"
                t={t}
                components={{
                  required: <span className={styles.required} />,
                  linkTag: (
                    <Link
                      target="_blank"
                      href={t("common:term-of-service.link") as string}
                      className={styles.link}
                    />
                  ),
                }}
              />
            }
            name="terms_of_service"
            onCheckedChange={(v: boolean) => setTermsAccepted(v)}
          />
          <Checkbox
            kind="checked"
            label={
              <Trans
                i18nKey="sign-in:privacy-policy-checkbox"
                t={t}
                components={{
                  linkTag: (
                    <Link
                      target="_blank"
                      href={t("common:privacy-policy.link") as string}
                      className={styles.link}
                    />
                  ),
                }}
              />
            }
            name="privacy_policy"
            onCheckedChange={(v: boolean) => setIsMarketingUpdatesChecked(v)}
          />
        </Flex>

        {/* SUBMIT BTNS */}
        <Button
          capitalize={false}
          dimension="regular"
          kind="primary"
          disabled={
            !password || !username || !!errors?.password || !termsAccepted
          }
          onClick={() => {
            setSubmitted(true);
            handleSubmit(onSubmit, onError)();
          }}
          fullWidth
        >
          {!submitting ? t("sign-in:sign-up-cta") : <Spinner />}
        </Button>
        <Text
          variant="s"
          className={styles.error}
          weight="semibold"
          textAlign="center"
        >
          {capitalizeFirstLetter(error)}
        </Text>
        <Text
          variant="m"
          style={{ width: "100%", marginBottom: "6px" }}
          textAlign="center"
          className={styles.description}
        >
          {t("sign-in:sign-in-social-description")}
        </Text>
        <Button
          capitalize={false}
          dimension="regular"
          kind="secondary-neutral"
          onClick={() => {
            saveAgreements();

            signInWithRedirect({ provider: "Google" });
          }}
          fullWidth
          disabled={!termsAccepted}
        >
          <Flex direction="row" rowGap={12} columnGap={12}>
            <Image
              src="/images/google_logo.svg"
              width={24}
              height={24}
              alt="google logo"
              data-disabled={!termsAccepted}
              className={styles.googleLogo}
            />
            <Text variant="m" weight="semibold">
              {t("sign-in:sign-up-google")}
            </Text>
          </Flex>
        </Button>
      </Flex>
    </FormProvider>
  );
};

export default SignUp;
