import { ForgotPassword, SignUp } from "@/components/auth";
import { PurchasePreviewBanner } from "@/components/auth/purchasePreviewBanner";
import { SignIn } from "@/components/auth/signIn";
import { Loader } from "@/components/common/loader";
import { UserContext } from "@/context/user";
import { useAnalytics } from "@/hooks/useAnalytics";
import { useSession } from "@/hooks/useSession";
import { handleSSRProductPreview, withAuth } from "@/lib/auth";
import { handleLanguageDetection } from "@/lib/lang";
import styles from "@/styles/authorize.module.scss";
import type { IProductPreview } from "@/types/types";
import { Button, Flex, Text } from "@talent-garden/react-components";
import { type GetServerSideProps, type NextPage } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";
import { CustomLink, Image } from "../../components";

type TSignInStep = "sign-in" | "sign-up" | "forgot-password" | "set-otp";

export const metadata = {
  title: "Blog",
  openGraph: {
    title: "Blog",
  },
};
const AuthorizePage: NextPage<{ purchaseItem: IProductPreview }> = ({
  purchaseItem,
}) => {
  const router = useRouter();
  const { query } = router;
  const { returnUrl } = query;
  const [step, setStep] = useState<TSignInStep>("sign-up");
  const { setPageView, captureInteraction } = useAnalytics();
  const { status } = useSession();
  const { ownerProfile } = useContext(UserContext);

  useEffect(() => {
    if (!ownerProfile) {
      return;
    }
    if (status === "authenticated") {
      // TODO: parte anche dopo la sign in e la sign up via mail >
      captureInteraction({
        event_category: "signin",
        event_label: "authenticated",
      });
      // store is the only page that should avoid redirecting to welcome because is there a logic inside
      router.push(
        (returnUrl?.includes("store")
          ? returnUrl
          : `/welcome${returnUrl ? `?returnUrl=${returnUrl}` : ""}`) as string,
      );
    }
  }, [status, router, returnUrl, ownerProfile]);

  const renderStep = useMemo(() => {
    switch (step) {
      case "sign-up":
        return (
          <SignUp
            goToSignIn={() => {
              setStep("sign-in");
            }}
            returnUrl={returnUrl ? String(returnUrl) : undefined}
          />
        );
      case "forgot-password":
        return (
          <ForgotPassword
            goToSignIn={() => {
              setStep("sign-in");
            }}
          />
        );
      case "sign-in":
      default:
        return (
          <SignIn
            goToSignUp={() => {
              setStep("sign-up");
            }}
            goToForgotPassword={() => {
              setStep("forgot-password");
            }}
            returnUrl={returnUrl ? String(returnUrl) : undefined}
          />
        );
    }
  }, [step]);

  useEffect(() => {
    if (!setPageView) {
      return;
    }
    setPageView({
      event_category: "login",
      event_label: "authorize",
    });
  }, [setPageView]);

  if (status === "loading" || status === "authenticated") {
    return <Loader />;
  }

  const support = (
    <Flex
      className={styles.support}
      verticalAlign="space-between"
      horizontalAlign="center"
    >
      <Text variant="s">{new Date().getFullYear()} © Talent Garden</Text>
      <CustomLink external href="mailto:support.sphere@talentgarden.com">
        <Button
          capitalize={false}
          dimension="small"
          icon="email"
          iconVariant="outlined"
          kind="link-neutral"
          fontWeight="regular"
        >
          support.sphere@talentgarden.com
        </Button>
      </CustomLink>
    </Flex>
  );

  return (
    <>
      <NextSeo />
      <Flex className={styles.container} direction="row">
        <Flex className={styles.left} horizontalAlign="center">
          <Flex className={styles.logo}>
            <Image
              src="/images/logo_tag+hi_white.svg"
              alt="Talent Garden learn logo"
              fill
            />
          </Flex>

          <Image
            src="/images/login-left-image.jpg"
            alt="Sign in page image"
            className={styles.bgImage}
            width={1440}
            height={1000}
          />
          {support}
        </Flex>
        <Flex
          direction="column"
          horizontalAlign="center"
          className={styles.bannerSignInWrapper}
        >
          <Flex className={styles.right} rowGap={24} columnGap={24}>
            <Flex className={styles.logo}>
              <Image
                src="/images/logo_tag+hi_coloured.svg"
                alt="Talent Garden learn logo"
                fill
              />
            </Flex>
            <PurchasePreviewBanner purchaseItem={purchaseItem} />
            <Flex className={styles.content}>{renderStep}</Flex>
            {support}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default AuthorizePage;

export const getServerSideProps: GetServerSideProps = handleLanguageDetection(
  withAuth(async ({ locale = "en", query, req }) => {
    const { returnUrl } = query;

    const proto = req.headers["x-forwarded-proto"] ? "https" : "http";
    const nextApiUrl = `${proto}://${req.headers.host}`;

    let purchaseItem = null;

    if (returnUrl) {
      const url = new URL(query.returnUrl as string, nextApiUrl);
      const {
        hs_object_id,
        content_id: contentId,
        product: productId,
      } = Object.fromEntries(url.searchParams.entries());

      if (hs_object_id || productId || contentId) {
        purchaseItem = await handleSSRProductPreview({
          hs_object_id,
          contentId,
          productId,
          req,
        });
      }
    }

    return {
      props: {
        purchaseItem,
        ...(await serverSideTranslations(locale, [
          "sign-in",
          "inputs",
          "common",
        ])),
      },
    };
  }),
);
