import { capitalizeFirstLetter } from "@/lib/utils";
import styles from "@/styles/authorize.module.scss";
import {
  Button,
  Flex,
  Heading,
  MaterialIcon,
  Text,
  TextField,
} from "@talent-garden/react-components";
import { resetPassword } from "aws-amplify/auth";
import { useTranslation } from "next-i18next";
import type { FC } from "react";
import React, { useState } from "react";
import { Spinner } from "../..";
import { ResetPassword } from "../resetPassword";

const ForgotPassword: FC<{ goToSignIn: () => void }> = ({ goToSignIn }) => {
  const { t } = useTranslation("sign-in");

  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [nextStep, setNextStep] = useState<{ username: string } | null>(null);

  if (nextStep) {
    return (
      <ResetPassword username={nextStep?.username} goToSignIn={goToSignIn} />
    );
  }

  return (
    <Flex rowGap={8} columnGap={8} horizontalAlign="start">
      <MaterialIcon
        name="arrow_back"
        size={32}
        variant="outlined"
        onClick={goToSignIn}
        className={styles.backIcon}
        mode="gray"
      />
      <Heading variant="m" weight="bold" style={{ paddingTop: "24px" }}>
        {t("forgot-password-title")}
      </Heading>
      <Text
        variant="m"
        style={{ marginBottom: "16px" }}
        className={styles.description}
      >
        {t("forgot-password-description")}
      </Text>
      <TextField
        dimension="medium"
        label={t("forgot-password-email-label") as string}
        placeholder={t("forgot-password-email-placeholder") as string}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.persist();
          setUsername(e.target.value);
        }}
      />
      <Button
        capitalize={false}
        dimension="regular"
        kind="primary"
        onClick={async () => {
          setSubmitting(true);
          try {
            setError("");
            const res = await resetPassword({ username });

            if (res?.nextStep) {
              setNextStep({ ...res.nextStep, username });
            }
          } catch (err: any) {
            console.log("Error: ", err);
            setError(err?.message as string);
          } finally {
            setSubmitting(false);
          }
        }}
        fullWidth
      >
        {!submitting ? t("forgot-password-cta") : <Spinner />}
      </Button>
      <Text
        variant="s"
        className={styles.error}
        weight="semibold"
        textAlign="center"
      >
        {capitalizeFirstLetter(error)}
      </Text>
    </Flex>
  );
};

export default ForgotPassword;
