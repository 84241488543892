import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

export type TTerms = {
    _id: string,
    version: string,
    source: string,
    [locale: string]: string,
}

export const useTermsOfService = () => {
    const { query } = useRouter()
    const { data: terms, isLoading: termsIsLoading, error: termsError } = useQuery(['terms'], async () => {
        return axios.get('/api/terms-of-service/')
    })

    const isLoading = !terms?.data || termsIsLoading

    const postUserTerms = useCallback(async (userId: string, data: any) => {
        try {
            await axios.post(`/api/terms-of-service?id=${userId}`, data)
        } catch (error) {
            throw ("Error postings terms to user")
        }
    }, [query?.id])

    return {
        isLoading,
        error: termsError,
        terms: terms?.data as TTerms,
        postUserTerms
    }
}
